import { useMutation } from '@apollo/client';
import { TextField } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import gql from 'graphql-tag';
import React, { useState } from 'react';

const SAVE_REGISTRATION_MUTATION = gql`
  mutation CompRegistrationUpsert($input: CompRegistrationInput!) {
    compRegistrationUpsert(input: $input) {
      id
    }
  }
`;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
      whiteSpace: 'pre-line',
    },
    button: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    divisionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    divisions: {
      maxWidth: 'max-content',
    },
    extraFields: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(4),
      display: 'grid',
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: 'repeat(1,minmax(0,1fr))',
      },
      gridTemplateColumns: 'repeat(2,minmax(0,1fr))',
      gap: '10px',
    },
  })
);

interface Props {
  track: any;
  currentUser: any;
  compRegistration: any;
  reload: () => Promise<any>;
}

const GENDERS = {
  M: 'Male',
  F: 'Female',
};

interface ExtraField {
  name: string;
  value: string;
  type: string;
  required: boolean;
}

const DivisionStep = ({
  track,
  currentUser,
  compRegistration,
  reload,
}: Props) => {
  const styles = useStyles();
  const [saving, setSaving] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [extraFields, setExtraFields] = useState<ExtraField[]>(
    track.compExtraFields
  );
  const [saveCompRegistrationMutation] = useMutation(
    SAVE_REGISTRATION_MUTATION
  );

  const hasDivisions =
    compRegistration.divisionOptions &&
    compRegistration.divisionOptions.length > 0;

  const saveCompRegistration = async () => {
    setSaving(true);
    try {
      const optionKey = hasDivisions ? 'division' : 'weightClass';
      await saveCompRegistrationMutation({
        variables: {
          input: {
            trackId: track.id,
            [optionKey]: selectedValue,

            extraFields: extraFields.length
              ? extraFields.map(({ value }) => ({ value }))
              : undefined,
          },
        },
      });
      await reload();
      window.scrollTo(0, 0);
    } finally {
      setSaving(false);
    }
  };

  const options = hasDivisions
    ? compRegistration.divisionOptions
    : compRegistration.weightGroupOptions;

  const formIsValid =
    !!selectedValue &&
    extraFields.filter((f) => f.required).every((f) => !!f.value);

  return (
    <div>
      <Box className={styles.instructions}>
        {hasDivisions ? (
          <>
            Gender category{' '}
            <strong>{GENDERS[compRegistration.gender] || 'Unknown'}</strong> is
          </>
        ) : (
          <>
            Age category <strong>{compRegistration.ageGroup.name}</strong> and
            gender category{' '}
            <strong>{GENDERS[compRegistration.gender] || 'Unknown'}</strong> are
          </>
        )}{' '}
        set by using your <strong>{currentUser.c2Username}</strong> account
        information.
        {'\r\n'}
        Please choose the {hasDivisions ? 'division' : 'weight category'} you'll
        be competing in:
      </Box>
      <Box className={styles.divisionsContainer}>
        <List className={styles.divisions}>
          {options.map((option) => {
            const labelId = `option-${option.value}`;

            return (
              <ListItem
                key={option.value}
                role={undefined}
                dense
                button
                onClick={() => setSelectedValue(option.value)}
              >
                <ListItemIcon>
                  <Radio
                    edge="start"
                    checked={option.value === selectedValue}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{
                      'aria-labelledby': labelId,
                    }}
                  />
                </ListItemIcon>
                <ListItemText
                  id={labelId}
                  primary={option.name}
                  secondary={option.details}
                />
              </ListItem>
            );
          })}
        </List>
        {extraFields.length > 0 && (
          <div>
            Please fill out some information required by the organizer:
            <div className={styles.extraFields}>
              {extraFields.map((extraField, i) => {
                return (
                  <TextField
                    key={i}
                    name={extraField.name}
                    required={extraField.required}
                    variant="outlined"
                    label={extraField.name}
                    onChange={(event) => {
                      const value = event.target.value;
                      setExtraFields((fields) => {
                        const newFields = [...fields];
                        newFields[i] = {
                          ...newFields[i],
                          value,
                        };
                        return newFields;
                      });
                    }}
                  />
                );
              })}
            </div>
          </div>
        )}
      </Box>
      <Button
        className={styles.button}
        disabled={!formIsValid || saving}
        variant="contained"
        color="primary"
        onClick={() => saveCompRegistration()}
      >
        Save
      </Button>
    </div>
  );
};

export default DivisionStep;
